
import SocialIcons from "./SocialIcons";

/**
 * Represents the footer section of the website.
 *
 * @component
 */

const Footer = () => {

  // Variants for button animation
  // const buttonVariants = {
  //   hover: {
  //     scale: 1.05,
  //   },
  //   tap: {
  //     scale: 1,
  //   },
  // };

  return (
    <footer>
      {/* Social icons */}
      <SocialIcons />
    </footer>
  );
};

export default Footer;
